import { useTranslation } from "react-i18next";

const DownloadPage = () => {
  const { t } = useTranslation();

  const handleClickBanner = (isIOS) => {
    isIOS
      ? window.open("https://apps.apple.com/vn/app/u-home/id1576336153")
      : window.open(
          "https://play.google.com/store/apps/details?id=vn.b2success.uhome"
        );
  };

  return (
    <section className="download__app">
      <picture>
        <source
          media="(max-width:1199.98px)"
          srcSet="./images/u-home-download-mb.webp"
        />
        <img alt="U-home" src="./images/u-home-download.webp" />
      </picture>
      <div className="download__qrCode">
        <h1 dangerouslySetInnerHTML={{ __html: t("download.title") }} />
        <div className="download__store">
          <div onClick={() => window.open("https://onelink.to/u-home")}>
            <img src="./images/QR_UHOME.png" alt="QR_UHOME" />
          </div>
          <div className="download__store-line">&nbsp;</div>
          <div>
            <img
              src="./images/tai-app-ios.png"
              alt="u-home - Tải app ios"
              onClick={() => handleClickBanner(true)}
            />
            <img
              src="./images/tai-app-apk.png"
              alt="u-home Tải app apk"
              onClick={() => handleClickBanner(false)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadPage;
