import React from "react";

const DATA_ABOUT = [
  {
    title: "Tối ưu hóa phương pháp truyền thống bằng công nghệ:",
    desc: [
      "Luôn luôn có thợ, đặt thợ đơn giản",
      "Tiết kiệm thời gian",
      "Trao đổi trực tiếp với thợ thông qua ứng dụng",
      "Phản hồi và quản lý tiến độ công việc online",
    ],
    alt: "u-home - Đặt lịch nhanh chóng",
    img: "./images/home/dat-lich-nhanh-chong.webp",
    imgMobile: "./images/home/mobile/dat-lich-nhanh-chong.webp",
  },
  {
    title: "Sửa chữa không lo về giá",
    desc: [
      "Chỉ cần trao đổi về thời gian sửa chữa",
      "Chi phí được hệ thống tính toán hợp lý theo thời gian làm việc",
      "Giá cả minh bạch rõ ràng, hợp lý với thị trường",
    ],
    alt: "u-home - Sửa chữa không lo về giá",
    img: "./images/home/khong-lo-ve-gia.webp",
    imgMobile: "./images/home/mobile/khong-lo-ve-gia.webp",
  },
  {
    title: "Chất lượng đội ngũ thợ lao động",
    desc: [
      "Sở hữu đội ngũ thợ với thông tin rõ ràng và kỹ năng được xác thực",
      "Mỗi lĩnh vực đều có thợ chuyên môn đảm nhiệm",
      "Được đào tạo về quy trình công việc và ứng xử hàng tháng",
      "Phản hồi khách hàng là tiêu chí quan trọng để đánh giá thợ",
    ],
    alt: "u-home - Chất lượng dịch vụ đảm bảo",
    img: "./images/home/chat-luong-dich-vu-dam-bao.webp",
    imgMobile: "./images/home/mobile/chat-luong-dich-vu-dam-bao.webp",
  },
  {
    title: "An toàn sử dụng, bảo mật tuyệt đối",
    desc: [
      "Thông tin người thợ được công khai minh bạch, rõ ràng",
      "Bảo mật thông tin khách hàng, an tâm sử dụng",
      "Mọi vấn đề đều được hệ thống theo dõi và kiểm tra liên tục",
    ],
    alt: "u-home - An toàn tuyệt đối cho nhà bạn",
    img: "./images/home/an-toan-tuyet-doi-cho-nha-ban.webp",
    imgMobile: "./images/home/mobile/an-toan-tuyet-doi-cho-nha-ban.webp",
  },
  {
    title: "Thích hợp cho mọi nhà",
    desc: [
      "Giao diện thân thiện và đơn giản hóa mọi thao tác",
      "Cung cấp các nhóm dịch vụ đa dạng đáp ứng mọi nhu cầu cuộc sống của bạn",
    ],
    alt: "u-home - Thích hợp cho mọi nhà",
    img: "./images/home/thich-hop-cho-moi-nha.webp",
    imgMobile: "./images/home/mobile/thich-hop-cho-moi-nha.webp",
  },
];

const SectionAbout = () => {
  return (
    <div className="section-about">
      <h1 className="section-about-title">
        Khám phá những tiện ích mà U-HOME mang lại nhằm phục vụ nhu cầu của bạn
      </h1>

      {DATA_ABOUT?.map((ele, index) => (
        <div className="section-about-content" key={`${index}-about`}>
          <div className="section-about-content__img">
            <picture>
              <source
                media="(max-width:767.98px)"
                srcSet={ele.imgMobile}
                width={343}
                height={265}
              />
              <source
                media="(max-width:991.98px)"
                src={ele.img}
                width={277}
                height={213}
              />
              <source
                media="(max-width:1199.98px)"
                src={ele.img}
                width={358}
                height={275}
              />
              <source
                media="(max-width:1339.98px)"
                src={ele.img}
                width={433}
                height={333}
              />
              <img
                src={ele.img}
                alt="U-HOME"
                width={520}
                height={400}
                className="image"
              />
            </picture>
          </div>
          <div className="section-about-content__text">
            <h3 className="section-about-content__text-title">{ele.title}</h3>
            <ul className="section-about-content__text-desc">
              {ele?.desc?.map((desc, i) => (
                <li key={`${index}-${i}`}>{desc}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(SectionAbout);
