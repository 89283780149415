import React from "react";
import { Link, useHistory } from "react-router-dom";

const DATA = [
  {
    id: 1,
    img: "./images/home/task.svg",
    title: "2.5k+",
    desc: "Công việc được hoàn thành",
  },
  {
    id: 2,
    img: "./images/home/people.svg",
    title: "2.2k+",
    desc: "Khách hàng hài lòng",
  },
  {
    id: 3,
    img: "./images/home/task.svg",
    title: "94%",
    desc: "Tương tác tích cực hàng ngày",
  },
];

const SectionBanner = () => {
  const history = useHistory();

  return (
    <div className="section-banner">
      <div className="section-banner-desc">
        <div className="section-banner-desc__bg">
          <picture>
            <source
              media="(max-width:767.98px)"
              srcSet="./images/home/mobile/u-home-banner.webp"
              width={375}
              height={223}
            />
            <source
              media="(max-width:991.98px)"
              srcSet="./images/home/u-home-banner.webp"
              width={768}
              height={427}
            />
            <source
              media="(max-width:1199.98px)"
              srcSet="./images/home/u-home-banner.webp"
              width={992}
              height={552}
            />
            <source
              media="(max-width:1339.98px)"
              srcSet="./images/home/u-home-banner.webp"
              width={1200}
              height={667}
            />
            <img
              alt="U-home"
              src="./images/home/u-home-banner.webp"
              width={1440}
              height={856}
            />
          </picture>
        </div>
        <div className="section-banner-desc__content">
          <div className="section-banner-desc__content-bg">
            <picture>
              <source
                media="(max-width:767.98px)"
                srcSet="./images/home/mobile/u-home-bg-content-banner.png"
                width={172}
                height={273}
              />
              <source
                media="(max-width:991.98px)"
                srcSet="./images/home/u-home-bg-content-banner.png"
                width={342}
                height={314}
              />
              <source
                media="(max-width:1199.98px)"
                srcSet="./images/home/u-home-bg-content-banner.png"
                width={441}
                height={404}
              />
              <source
                media="(max-width:1339.98px)"
                srcSet="./images/home/u-home-bg-content-banner.png"
                width={534}
                height={490}
              />
              <img
                src="./images/home/u-home-bg-content-banner.png"
                alt="U-HOME"
                loading="lazy"
                width={636}
                height={584}
                className="image"
              />
            </picture>
          </div>
          <div className="section-banner-desc__content-info">
            <h2 className="">
              U-Home Ứng dụng đặt thợ thi công, sửa chữa tại nhà
            </h2>
            <p>
              Từ những dịch vụ sửa chữa, thi công và lắp đặt thiết yếu, đến cơ
              hội gia tăng thu nhập. Tất cả đều trên một nền tảng.
            </p>
            <div>
              <Link to={"#download-app"}>Tải ứng dụng</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="section-banner-info">
        <div className="section-banner-info__content">
          {DATA.map((ele, index) => (
            <div className="item" key={`${index}-${ele.id}`}>
              <h3 className="section-banner-info__content-title">
                <picture>
                  <source
                    media="(max-width:767.98px)"
                    srcSet={ele.img}
                    alt="U-HOME"
                    loading="lazy"
                    width={20}
                    height={20}
                  />
                  <img
                    src={ele.img}
                    alt="U-HOME"
                    loading="lazy"
                    width={40}
                    height={40}
                  />
                </picture>
                <span>{ele.title}</span>
              </h3>
              <p className="section-banner-info__content-desc">{ele.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SectionBanner);
