import React, { Suspense, useLayoutEffect } from "react";
import Header from "./layout/Header";
import HomePage from "./layout/Home";
import Privacy from "./layout/Privacy";
import { Switch, Route, useLocation, BrowserRouter } from "react-router-dom";
import DownloadPage from "./layout/Download";
import { ROUTER } from "./shared/common";
import SupportsPage from "./layout/Supports";

const Footer = React.lazy(() => import("./layout/Footer"));

export const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  return (
    <Suspense fallback={<div />}>
      <BrowserRouter>
        <div className="container">
          <Header />
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path={ROUTER.PRIVACY}>
              <ScrollToTop>
                <Privacy />
              </ScrollToTop>
            </Route>
            <Route path={ROUTER.DOWNLOAD_APP}>
              <ScrollToTop>
                <DownloadPage />
              </ScrollToTop>
            </Route>
            <Route path={ROUTER.SUPPORTS}>
              <ScrollToTop>
                <SupportsPage />
              </ScrollToTop>
            </Route>
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
