import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb";
import MultiMenus from "../components/multiMenus";
import { ROUTER } from "../shared/common";
import DeleteAccounts from "./DeleteAccounts";

const BREADCRUMB_SUPPORTS = [
  { label: "Hướng dẫn xoá thông tin tài khoản", path: ROUTER.DELETE_ACCOUNT },
];

const SupportsPage = () => {
  const { t } = useTranslation();
  const [isListOpen, setIsListOpen] = useState(false);

  const menus = [
    {
      label: t("footer.support"),
      submenu: [
        {
          label: "Xoá thông tin tài khoản",
          path: ROUTER.DELETE_ACCOUNT,
        },
      ],
    },
  ];

  useEffect(() => {
    document.body.classList.toggle("modal-open", isListOpen);
  }, [isListOpen]);

  return (
    <div className="supports-wrapper">
      {window.innerWidth <= 768 && (
        <p
          className="btn-sidebar-open"
          onClick={() => setIsListOpen(!isListOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="currentColor"
            className="bi bi-text-indent-left"
            viewBox="0 0 16 16"
          >
            <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
          </svg>
        </p>
      )}
      <div className="supports">
        <div className={`supports-category  ${isListOpen ? "active" : ""}`}>
          {window.innerWidth <= 768 && (
            <div
              className="btn-sidebar-close"
              onClick={() => setIsListOpen(!isListOpen)}
            >
              <p>
                <Link to="/">
                  <img src="/images/icon/logo_uhome.svg" alt="" />
                </Link>
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="currentColor"
                className="bi bi-text-indent-right"
                viewBox="0 0 16 16"
              >
                <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm10.646 2.146a.5.5 0 0 1 .708.708L11.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zM2 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
            </div>
          )}
          <MultiMenus menus={menus} />
        </div>
        <div className="supports-content">
          <Breadcrumb breadcrumbs={BREADCRUMB_SUPPORTS} />
          <Switch>
            <Route exact path={ROUTER.SUPPORTS}>
              <Redirect to={ROUTER.DELETE_ACCOUNT} />
            </Route>
            <Route path={ROUTER.DELETE_ACCOUNT}>
              <DeleteAccounts />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default SupportsPage;
