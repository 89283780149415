import React from "react";

const DATA_FEATURE = [
  {
    id: 1,
    img: "./images/home/light-bulb.svg",
    title: "Bạn tạo việc, thợ U-HOME hỗ trợ ngay tức thời.",
    desc: [
      "Tạo yêu cầu sửa chữa, thi công đơn giản, nhanh chóng.",
      "Thợ liên hệ ngay lập tức",
    ],
  },
  {
    id: 2,
    img: "./images/home/elearning.svg",
    title: "Giải quyết mọi vấn đề của bạn",
    desc: [
      "Đội ngũ CSKH luôn hỗ trợ 24/7",
      "Luôn giải quyết mọi vấn đề khi bạn cần",
    ],
  },
  // {
  //   id: 3,
  //   img: "./images/home/money.svg",
  //   title: "Không lo về giá",
  //   desc: [
  //     "Bạn và thợ chỉ trao đổi về thời gian làm việc",
  //     "Chi phí sẽ được hệ thống tính toán hợp lí nhất",
  //     'Luôn có những "free code" siêu khủng',
  //   ],
  // },
  {
    id: 4,
    img: "./images/home/user-protection.svg",
    title: "Đánh giá chất lượng và phản hồi công việc trực tiếp",
    desc: [
      "Quá trình làm việc được đánh giá và phản hồi trên ứng dụng",
      "Phản hồi của bạn là tiêu chí quan trọng để đánh giá người thợ",
    ],
  },
];

const SectionFeature = () => {
  return (
    <div className="section-container" id="benefit">
      <div className="section-feature">
        <h2 className="section-feature__title">U-Home có những gì?</h2>

        <div className="section-feature-content-wrapper">
          <div className="section-feature-content-top">
            <div className="section-feature-content-top__img">
              <picture>
                <source
                  media="(max-width:767.98px)"
                  srcSet="./images/home/mobile/u-home-co-nhung-gi.webp"
                  width={271}
                  height={546}
                />
                <source
                  media="(max-width:991.98px)"
                  srcSet="./images/home/u-home-co-nhung-gi.webp"
                  width={212}
                  height={371}
                />
                <source
                  media="(max-width:1199.98px)"
                  srcSet="./images/home/u-home-co-nhung-gi.webp"
                  width={274}
                  height={480}
                />
                <source
                  media="(max-width:1339.98px)"
                  srcSet="./images/home/u-home-co-nhung-gi.webp"
                  width={331}
                  height={580}
                />
                <img
                  src="./images/home/u-home-co-nhung-gi.webp"
                  alt="u-home - Có những gì?"
                  width={398}
                  height={697}
                  className="image"
                />
              </picture>
            </div>

            <div className="section-feature-content-top__detail">
              {DATA_FEATURE.slice(0, 3).map((ele, index) => (
                <div className="item" key={`${ele.id}-${index}`}>
                  <div className="section-feature-content__icon">
                    <picture>
                      <source
                        media="(max-width:767.98px)"
                        srcSet={ele.img}
                        width={48}
                        height={48}
                      />
                      <source
                        media="(max-width:991.98px)"
                        srcSet={ele.img}
                        width={32}
                        height={32}
                      />
                      <source
                        media="(max-width:1199.98px)"
                        srcSet={ele.img}
                        width={42}
                        height={42}
                      />
                      <source
                        media="(max-width:1339.98px)"
                        srcSet={ele.img}
                        width={50}
                        height={50}
                      />
                      <img
                        src={ele.img}
                        alt="u-home - Có những gì?"
                        width={60}
                        height={60}
                      />
                    </picture>
                  </div>
                  <h3 className="section-feature-content__heading">
                    {ele.title}
                  </h3>
                  <ul className="section-feature-content__list">
                    {ele.desc.map((desc, i) => (
                      <li key={`${ele.id}-${index}-${i}`}>{desc}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {DATA_FEATURE.slice(3).map((ele, index) => (
            <div
              className="section-feature-content-bottom"
              key={`${ele.id}-${index}`}
            >
              <div className="section-feature-content__icon">
                <picture>
                  <source
                    media="(max-width:767.98px)"
                    srcSet={ele.img}
                    width={48}
                    height={48}
                  />
                  <source
                    media="(max-width:991.98px)"
                    srcSet={ele.img}
                    width={32}
                    height={32}
                  />
                  <source
                    media="(max-width:1199.98px)"
                    srcSet={ele.img}
                    width={42}
                    height={42}
                  />
                  <source
                    media="(max-width:1339.98px)"
                    srcSet={ele.img}
                    width={50}
                    height={50}
                  />
                  <img
                    src={ele.img}
                    alt="u-home - Có những gì?"
                    width={60}
                    height={60}
                  />
                </picture>
              </div>
              <h3 className="section-feature-content__heading">{ele.title}</h3>
              <ul className="section-feature-content__list">
                {ele.desc.map((desc, i) => (
                  <li key={`${ele.id}-${index}-${i}`}>{desc}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SectionFeature);
