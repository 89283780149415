import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = ({ breadcrumbs }) => {
  const { pathname } = useLocation();
  return (
    <div className="breadcrumb">
      <Link to="/">
        <span>Trang chủ</span>
      </Link>
      <span className="breadcrumb-arrow">&#62;</span>
      {breadcrumbs.map((breadcrumb) => {
        if (pathname === breadcrumb.path) {
          return (
            <span key={breadcrumb.path} className="breadcrumb-active">
              {breadcrumb.label}
            </span>
          );
        }
        return (
          <React.Fragment key={breadcrumb.path}>
            <Link to={breadcrumb.path}>
              <span>{breadcrumb.label}</span>
            </Link>
            <span className="breadcrumb-arrow">&#62;</span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
