import React from "react";

const SectionDownload = () => {
  return (
    <div className="section-download" id="download-app">
      <div className="section-download-bg">
        <picture>
          <source
            media="(max-width:767.98px)"
            srcSet="./images/home/mobile/bg-section-download.webp"
            width={375}
            height={618}
          />
          <source
            media="(max-width:991.98px)"
            srcSet="./images/home/bg-section-download.webp"
            width={768}
            height={373}
          />
          <source
            media="(max-width:1199.98px)"
            srcSet="./images/home/bg-section-download.webp"
            width={992}
            height={482}
          />
          <source
            media="(max-width:1339.98px)"
            srcSet="./images/home/bg-section-download.webp"
            width={1200}
            height={583}
          />
          <img
            src="./images/home/bg-section-download.webp"
            alt="u-home"
            width={1440}
            height={700}
            className="image"
          />
        </picture>
      </div>

      <div className="section-download-content">
        <h2 className="section-download__title">Tải ứng dụng ngay</h2>
        <p className="section-download__desc">
          U-HOME giải quyết mọi vấn đề khi bạn cần. <br />
          Mang lại trải nghiệm sống tốt hơn cho mọi nhà.
        </p>

        <div className="section-download__img">
          <div className="section-download__img-store">
            <a
              href="https://apps.apple.com/vn/app/u-home/id1576336153"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture>
                <source
                  media="(max-width:767.98px)"
                  srcSet="./images/home/tai-app-ios.svg"
                  width={120}
                  height={38}
                />
                <source
                  media="(max-width:991.98px)"
                  srcSet="./images/home/tai-app-ios.svg"
                  width={107}
                  height={34}
                />
                <source
                  media="(max-width:1199.98px)"
                  srcSet="./images/home/tai-app-ios.svg"
                  width={138}
                  height={44}
                />
                <source
                  media="(max-width:1339.98px)"
                  srcSet="./images/home/tai-app-ios.svg"
                  width={167}
                  height={53}
                />
                <img
                  src="./images/home/tai-app-ios.svg"
                  alt="u-home - Tải app ios"
                  loading="lazy"
                  width={200}
                  height={64}
                />
              </picture>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=vn.b2success.uhome"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture>
                <source
                  media="(max-width:767.98px)"
                  srcSet="./images/home/tai-app-apk.svg"
                  width={120}
                  height={38}
                />
                <source
                  media="(max-width:991.98px)"
                  srcSet="./images/home/tai-app-apk.svg"
                  width={107}
                  height={34}
                />
                <source
                  media="(max-width:1199.98px)"
                  srcSet="./images/home/tai-app-apk.svg"
                  width={138}
                  height={44}
                />
                <source
                  media="(max-width:1339.98px)"
                  srcSet="./images/home/tai-app-apk.svg"
                  width={167}
                  height={53}
                />
                <img
                  src="./images/home/tai-app-apk.svg"
                  alt="u-home Tải app apk"
                  loading="lazy"
                  width={200}
                  height={64}
                />
              </picture>
            </a>
          </div>

          <div className="section-download__img-qr">
            <a
              href="https://onelink.to/u-home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture>
                <source
                  media="(max-width:767.98px)"
                  srcSet="./images/QR_UHOME.png"
                  width={86}
                  height={86}
                />
                <source
                  media="(max-width:991.98px)"
                  srcSet="./images/QR_UHOME.png"
                  width={82}
                  height={82}
                />
                <source
                  media="(max-width:1199.98px)"
                  srcSet="./images/QR_UHOME.png"
                  width={105}
                  height={105}
                />
                <source
                  media="(max-width:1339.98px)"
                  srcSet="./images/QR_UHOME.png"
                  width={127}
                  height={127}
                />
                <img
                  src="./images/QR_UHOME.png"
                  alt="QR_UHOME"
                  loading="lazy"
                  width={152}
                  height={152}
                />
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SectionDownload);
