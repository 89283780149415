import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

const urlComma = "./images/home/comma.svg";

const renderRate = (rate) => {
  const arr = ["", "", "", "", ""];
  return arr.map((_item, index) => {
    const urlStar =
      index < rate
        ? "./images/home/feedback/star-full.svg"
        : "./images/home/feedback/star-half.svg";

    return (
      <picture key={index}>
        <source
          media="(max-width:767.98px)"
          srcSet={urlStar}
          width={14}
          height={14}
        />
        <source
          media="(max-width:991.98px)"
          srcSet={urlStar}
          width={11}
          height={11}
        />
        <source
          media="(max-width:1199.98px)"
          srcSet={urlStar}
          width={14}
          height={14}
        />
        <source
          media="(max-width:1339.98px)"
          srcSet={urlStar}
          width={16}
          height={16}
        />
        <img src={urlStar} alt="u-home" loading="lazy" width={19} height={19} />
      </picture>
    );
  });
};

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img
        src="./images/home/feedback/arrow-circle-right.svg"
        alt="u-home"
        loading="lazy"
      />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img
        src="./images/home/feedback/arrow-circle-left.svg"
        alt="u-home"
        loading="lazy"
      />
    </div>
  );
}

const SectionFeedback = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const DATA_FEEDBACK = [
    {
      img: "./images/home/feedback/Minh-Phuong-phan-hoi-y-kien.webp",
      comment:
        "App hữu dụng, đúng nhu cầu mọi nhà đều cần, giá cả hợp lý. Điều mình thích là bộ phận CSKH làm việc rất có trách nhiệm. Khi mình đánh giá 4 sao vì thợ phản hồi chậm, đã có nhân viên gọi và hỏi rõ nguyên nhân, quan tâm khách hàng.",
      rate: 5,
      author: "Minh Phương",
      job: t("body.feedback.officeStaff"),
      alt: "u-home - Phản hồi ý kiến sử dụng",
    },
    {
      img: "./images/home/feedback/Bao-Nguyen-phan-hoi-y-kien.webp",
      comment:
        "Mình đặt thợ sửa chữa cho siêu thị, mặc dù là công việc vào ban đêm nhưng thợ vẫn rất nhiệt tình sửa chữa, giá cả vô cùng phải chăng và đảm bảo về chất lượng. Đặc biệt là hàng Made in Việt Nam nên mình sẽ ủng hộ hết mình.",
      rate: 5,
      author: "Bảo Nguyễn",
      job: t("body.feedback.supermarketManager"),
      alt: "u-home - Phản hồi ý kiến sử dụng",
    },
    {
      img: "./images/home/feedback/Dinh-Le-Huynh-An-phan-hoi-y-kien.webp",
      comment:
        "Mình là thợ đăng ký công việc qua App U-Home, bản thân mình thấy App rất hay, thợ có thêm công việc vào lúc rảnh. Đặc biệt U-Home còn có chương trình thợ giới thiệu thợ nhận tiền hay một số chương trình tặng voucher tiền cho thợ.",
      rate: 5,
      author: "Đinh Lê Huỳnh An",
      job: t("body.feedback.electrician"),
      alt: "u-home - Phản hồi ý kiến sử dụng",
    },
    {
      img: "./images/home/feedback/Vu-Van-Truong-phan-hoi-y-kien.webp",
      comment:
        "Mình được một người bạn giới thiệu ứng dụng U-Home để đăng ký trở thành thợ. Đối với mình App dễ dùng, tiện lợi, giúp mình kiếm thêm nguồn thu nhập trong cuộc sống. Công việc được kê khai rõ ràng về công thợ, thanh toán nhanh chóng.",
      rate: 5,
      author: "Vũ Văn Trường",
      job: t("body.feedback.refrigeration"),
      alt: "u-home - Phản hồi ý kiến sử dụng",
    },
    {
      img: "./images/home/feedback/Nguyen-Quoc-Thai-phan-hoi-y-kien.webp",
      comment:
        "Mình đăng ký trở thành thợ U-Home từ tháng 6 năm 2022. Công việc bên U-Home rất linh động, nhiều nhóm dịch vụ khác nhau. Khi đăng ký trở thành thợ của U-Home sẽ không mất bất cứ một chi phí gì khi tham gia hệ thống.",
      rate: 5,
      author: "Nguyễn Quốc Thái",
      job: t("body.feedback.mechanic"),
      alt: "u-home - Phản hồi ý kiến sử dụng",
    },
  ];

  return (
    <div className="section-container" id="user-comment">
      <div className="section-feedback">
        <h2 className="section-feedback-title">Khách hàng nói về U-Home</h2>
        <Slider {...settings} className="section-feedback-slider">
          {DATA_FEEDBACK.map((feedback, index) => (
            <div key={index}>
              <div className="item-slider">
                <div className="section-feedback-slider__img">
                  <picture>
                    <source
                      media="(max-width:767.98px)"
                      srcSet={feedback.img}
                      width={319}
                      height={186}
                    />
                    <source
                      media="(max-width:991.98px)"
                      src={feedback.img}
                      width={235}
                      height={137}
                    />
                    <source
                      media="(max-width:1199.98px)"
                      src={feedback.img}
                      width={304}
                      height={177}
                    />
                    <source
                      media="(max-width:1339.98px)"
                      src={feedback.img}
                      width={368}
                      height={214}
                    />
                    <img
                      src={feedback.img}
                      alt={feedback.alt}
                      loading="lazy"
                      width={434}
                      height={258}
                      className="image"
                    />
                  </picture>
                </div>
                <div className="section-feedback-slider__text">
                  <div className="section-feedback-slider__text-comma">
                    <picture>
                      <source
                        media="(max-width:767.98px)"
                        srcSet={urlComma}
                        width={32}
                        height={32}
                      />
                      <source
                        media="(max-width:991.98px)"
                        src={urlComma}
                        width={22}
                        height={22}
                      />
                      <source
                        media="(max-width:1199.98px)"
                        src={urlComma}
                        width={28}
                        height={28}
                      />
                      <source
                        media="(max-width:1339.98px)"
                        src={urlComma}
                        width={34}
                        height={34}
                      />
                      <img
                        src={urlComma}
                        alt="u-home"
                        loading="lazy"
                        width={40}
                        height={40}
                        className="image"
                      />
                    </picture>
                  </div>
                  <p className="section-feedback-slider__text-content">
                    {feedback.comment}
                  </p>
                  <div className="section-feedback-slider__text-star">
                    {renderRate(feedback.rate || 5)}
                  </div>
                  <p className="section-feedback-slider__text-name">
                    {feedback.author}
                  </p>
                  <p className="section-feedback-slider__text-job">
                    {feedback.job}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default React.memo(SectionFeedback);
