import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SectionBanner from "../components/SectionBanner";
import SectionAbout from "../components/SectionAbout";
import SectionDownload from "../components/SectionDownload";
import SectionFeature from "../components/SectionFeature";
import SectionFeedback from "../components/SectionFeedback";

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <main className="home-page">
      <SectionBanner />
      <SectionAbout />
      <SectionFeedback />
      <SectionFeature />
      <SectionDownload />
    </main>
  );
};

export default HomePage;
