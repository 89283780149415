import React from "react";

const DeleteAccounts = () => {
  return (
    <div>
      <h1 className="supports__title">
        Hướng dẫn xoá thông tin tài khoản U-Home
      </h1>
      <div>
        <p>
          <span>Để </span>
          <strong>xóa tài khoản U-Home </strong>
          <span>
            khi bạn không có nhu cầu sử dụng nữa, hãy làm theo các bước sau:
          </span>
        </p>

        <div>
          <h2 className="supports__heading">
            1. Xoá thông tin tài khoản với hệ điều hành iOS:
          </h2>

          <ol>
            <li>
              <span>
                <strong>Bước 1: </strong>
              </span>
              <span>Truy cập vào ứng dụng U-Home trên điện thoại</span>
            </li>
            <li>
              <span>
                <strong>Bước 2: </strong>
              </span>
              <span>Vào mục Tôi</span>
            </li>
            <li>
              <span>
                <strong>Bước 3: </strong>
              </span>
              <span>Chọn Xóa tài khoản</span>
            </li>
            <li>
              <span>
                <strong>Bước 4: </strong>
              </span>
              <span>Nhập mật khẩu hiện tại</span>
            </li>
            <li>
              <span>
                <strong>Bước 5: </strong>
              </span>
              <span>Chọn Xóa để xóa tài khoản</span>
            </li>
          </ol>

          <div className="supports__img">
            <figure>
              <img src="/images/huong-dan-xoa-tai-khoan.jpeg" alt="U-Home" />
              <figcaption>
                <em>Hướng dẫn xoá thông tin tài khoản U-Home - iOS</em>
              </figcaption>
            </figure>
          </div>
        </div>

        <div>
          <h2 className="supports__heading">
            2. Xoá thông tin tài khoản với hệ điều hành Android:
          </h2>

          <ol>
            <li>
              <span>
                <strong>Bước 1: </strong>
              </span>
              <span>Truy cập vào ứng dụng U-Home trên điện thoại</span>
            </li>
            <li>
              <span>
                <strong>Bước 2: </strong>
              </span>
              <span>Vào mục Tôi</span>
            </li>
            <li>
              <span>
                <strong>Bước 3: </strong>
              </span>
              <span>Chọn Xóa tài khoản</span>
            </li>
            <li>
              <span>
                <strong>Bước 4: </strong>
              </span>
              <span>Nhập mật khẩu hiện tại</span>
            </li>
            <li>
              <span>
                <strong>Bước 5: </strong>
              </span>
              <span>Chọn Xác nhận để xóa tài khoản</span>
            </li>
          </ol>

          <div className="supports__img">
            <figure>
              <img src="/images/remove_acc_tut.png" alt="U-Home" />
              <figcaption>
                <em>Hướng dẫn xoá thông tin tài khoản U-Home - Android</em>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>

      <div className="supports__warning">
        <blockquote>
          <strong>⚠️ </strong>
          Các lưu ý trước khi xóa thông tin tài khoản trên U-Home
          <span>&nbsp;</span>
        </blockquote>

        <blockquote>
          <span>1. Người dùng không thể </span>
          <strong>đăng nhập</strong>
          <span> hoặc </span>
          <strong>xem lại các thông báo </strong>
          <span>trong ứng dụng sau khi tài khoản đã bị xóa.&nbsp;</span>
        </blockquote>

        <blockquote>
          <span>
            2. Người dùng phải đăng nhập vào tài khoản của U-Home trước khi thực
            hiện thao tác xoá / huỷ tài khoản.&nbsp;
          </span>
        </blockquote>
      </div>
    </div>
  );
};

export default DeleteAccounts;
