import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { ROUTER } from "../shared/common";

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isOpenMenu, setOpenMenu] = useState(false);

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__left">
          <Link to="/">
            <img src="/images/icon/logo_uhome.svg" alt="u-home" />
          </Link>
        </div>
        <div className="header__menu">
          <img
            src="/images/icon/hamburgerMenu.svg"
            width={32}
            height={24}
            alt="U-home"
            onClick={() => setOpenMenu(!isOpenMenu)}
          />
          <div style={{ right: isOpenMenu && "0" }}>
            <img
              src="/images/icon/arrowBack.svg"
              width={40}
              height={40}
              alt="u-home"
              onClick={() => setOpenMenu(!isOpenMenu)}
            />
            <p
              onClick={() => {
                history.push("/#user-comment");
                setOpenMenu(!isOpenMenu);
              }}
            >
              {t("header.ourCustomers")}
            </p>
            <p
              onClick={() => {
                history.push("/#benefit");
                setOpenMenu(!isOpenMenu);
              }}
            >
              {t("header.theBenefits")}
            </p>
            <p
              onClick={() => {
                history.push(ROUTER.SUPPORTS);
                setOpenMenu(!isOpenMenu);
              }}
            >
              {t("footer.support")}
            </p>
            <p
              onClick={() => {
                history.push(ROUTER.DOWNLOAD_APP);
                setOpenMenu(!isOpenMenu);
              }}
            >
              {t("header.downloadTheUhomeApp")}
            </p>
            <p className="hotline">
              <span>Hotline: </span>
              <a href="tel:19008645">19008645</a>
            </p>

            <div className="header__menu-download">
              <div className="header__menu-download-wrapper">
                <p className="header__menu-download-title">Tải ứng dụng</p>
                <div className="header__menu-download-store">
                  <a
                    href="https://apps.apple.com/vn/app/u-home/id1576336153"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/home/tai-app-ios.svg"
                      alt="u-home - Tải app ios"
                      loading="lazy"
                      width={160}
                      height={51}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=vn.b2success.uhome"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/home/tai-app-apk.svg"
                      alt="u-home Tải app apk"
                      loading="lazy"
                      width={160}
                      height={51}
                    />
                  </a>
                </div>
                <p className="header__menu-download-or">Hoặc</p>
                <div className="header__menu-download-qr">
                  <a
                    href="https://onelink.to/u-home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/QR_UHOME.png"
                      alt="QR_UHOME"
                      loading="lazy"
                      width={160}
                      height={160}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header__right">
          <p>
            <Link to="/#user-comment">{t("header.ourCustomers")}</Link>
          </p>
          <p>
            <Link to="/#benefit">{t("header.theBenefits")}</Link>
          </p>
          <p className="hotline">
            {"Hotline: "}
            <a href="tel:19008645">19008645</a>
          </p>
          <p className="active">
            <Link to={ROUTER.DOWNLOAD_APP}>
              {t("header.downloadTheUhomeApp")}
            </Link>
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
