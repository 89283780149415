const Eng = {
  translation: {
    header: {
      ourCustomers: "Our customers",
      theBenefits: "The benefits",
      downloadTheUhomeApp: "Download the U-Home App",
    },
    body: {
      slideShow: {
        banner1: "Peeling/ Cracking/ Bubbling paint on Walls",
        banner2:
          "Dripping or leaky bathroom sink faucet, bathtub faucet /drain, toilet tank",
        banner3:
          "Flickering/ Blinking/Dimming lights, broken wires, electrical problems",
        banner4: "Can't find a repairmen",
        fixNow: "U-Home comes to fix it right away",
        downloadNow: "Download now",
      },
      slideComment: {
        title: "What do the customers say? ",
      },
      bannerAds: {
        ad1: {
          title: { t1: "Very busy?", t2: "Don't have many time?" },
          line: {
            l1: "U-Home helps you find repairmen automatically and quickly",
            l2: "Manages work progress online",
            l3: "Acts as a liaison between customers and the mechanics ",
          },
        },
        ad2: {
          title: { t1: "Are you worried about service quality?" },
          line: {
            l1: "The repairmen's professional competence is evaluated according to the standards of the highly experienced team",
            l2: "Bilateral review, allowing customers and repairers to write reviews on the app. The customer's feedbacks measure the repairer's work performance.",
          },
        },
        ad3: {
          title: { t1: "Ensure security" },
          line: {
            l1: "The repairer's personal information is verified",
            l2: "Everything is tracked and checked meticulously",
          },
        },
        ad4: {
          title: { t1: "Don't be worry about the repair costs" },
          line: {
            l1: "Only discuss mean time to repair, you do not need to bargain",
            l2: "The cost is very reasonable. ",
            l3: " Additional costs (materials, supplies) are calculated separately",
          },
        },
      },
      bannerSupply: {
        title: "What does U-Home have?",
        block1: {
          title: "Your problems are addressed quickly and effectively.",
          line: {
            l1: "- Allowing for quick issue content creation",
            l2: "- The technician will contact you immediately",
          },
        },
        block2: {
          title: "Evaluate and send feedback on the quality of work",
          line: {
            l1: "- Using built-in commands to track the work progress.",
            l2: "- Customer feedback is essential for measuring repairer's work performance.",
          },
        },
        block3: {
          title: "Don't worry about the cost",
          line: {
            l1: "- Just discuss the mean time to repair",
            l2: "- The cost will be calculated most reasonable via our system",
            l3: "- Many hot discount codes ",
          },
        },
        block4: {
          title: "Helper is willing to help you",
          line: {
            l1: "- Customer service representative is always available",
            l2: "- U-Home here - your problem solved",
          },
        },
      },
      bannerApp: {
        title: "Dowload the U-Home App Now!",
        description:
          "U-Home- Bringing you a whole new experience. Your problem is always addressed by U-Home.",
      },
      feedback: {
        customer: "Customer",
        electricianPlumber: "Electrician - Plumber",
        plumber: "Plumber",
        electrician: "Electrician",
        designer: "Designer",
        developer: "Developer",
        freelance: "Freelance",
        officeStaff: "Office staff",
        supermarketManager: "Supermarket manager",
        refrigeration: "Refrigeration",
        mechanic: "Mechanic",
      },
    },
    footer: {
      paymentIntermediary1: "U-Home group joint stock company.",
      paymentIntermediary2: "Tax identification number: 0402091984",
      information: "Information",
      support: "Support",
      privacyPolicy: "Privacy Policy",
      termsAndConditions: "Terms and Conditions",
    },
    download: {
      title: "Download The <br /> U-Home App Now!",
    },
  },
};

export default Eng;
