import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="privacy">
      <div className="privacy-wrapper">
        <h1>CHÍNH SÁCH BẢO MẬT</h1>
        <p>
          <i>Bản sửa đổi lần cuối cùng ngày 2 tháng 3 năm 2023</i>
        </p>
        <p>
          Trang Chính sách bảo mật này công bố cách thức mà&nbsp;
          <strong>CÔNG TY CỔ PHẦN TẬP ĐOÀN U-HOME</strong> thu nhập, lưu trữ
          thông tin và dữ liệu cá nhân của người dùng thông qua các phương thức
          quy định các Chính sách bảo mật khi bạn truy cập vào các phần mềm của
          U-Home;&nbsp;<strong>Bao gồm</strong>: Ứng dụng U-Home, Website:&nbsp;
          <Link to="/">https://www.u-home.vn/</Link> và các phần mềm khác thuộc
          sở hữu của công ty.
        </p>
        <p>
          Chúng tôi đã chuẩn bị cam kết Chính sách Bảo mật này để mô tả cho bạn
          các thực tiễn của chúng tôi về dữ liệu cá nhân mà chúng tôi thu thập,
          sử dụng và chia sẻ liên quan đến trang Web U-Home, ứng dụng dành cho
          thiết bị di động và phần mềm khác được cung cấp trên hoặc liên quan
          đến các dịch vụ của chúng tôi, như được mô tả trong Điều khoản dịch vụ
          của chúng tôi (Gọi chung là “Dịch vụ”). Người dùng được khuyến nghị để
          nắm rõ những quyền lợi của mình khi sử dụng các dịch vụ của U-Home
          được cung cấp trên Website này và các phần mềm khác thuộc sở hữu của
          công ty.
        </p>
        <p>
          U-Home đưa ra các cam kết dưới đây phù hợp với các quy định của pháp
          luật Việt Nam, trong đó bao gồm các cách thức mà chúng tôi sử dụng để
          bảo mật thông tin của người dùng theo quy định trên.
        </p>

        <ol>
          <li>
            <p>
              <span>CÁC LOẠI DỮ LIỆU, THÔNG TIN  MÀ CHÚNG TÔI THU THẬP</span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>Dữ liệu cá nhân mà bạn cung cấp</span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>Địa chỉ Email;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>Giới tính;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>Ngày sinh;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>Địa chỉ khách hàng (Người dùng);</span>
                </p>
              </li>
              <li>
                <p>
                  <span>Số điện thoại;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Một đoạn video/hình ảnh ghi lại giấy tờ xác thực của bạn;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thông tin về thanh toán nếu như thanh toán bằng hình thức
                    chuyển khoản như tên chuyển khoản, số tài khoản.
                  </span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Để có thể nhận được sự phục vụ, người sử dụng cần đảm bảo
                    tính chính xác và đầy đủ của các thông tin cung cấp cho
                    chúng tôi. Trường hợp người dùng cung cấp cho U-Home các
                    thông tin cá nhân không chính xác hoặc không đầy đủ để xác
                    nhận được, chúng tôi không thể bảo vệ được quyền bảo mật của
                    người dùng theo quy định trên.
                  </span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Người dùng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi
                    hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp
                    thư điện tử của mình. Ngoài ra, người dùng có trách nhiệm
                    thông báo kịp thời cho Ứng dụng sàn giao dịch TMĐT U-Home về
                    những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật,
                    lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện
                    pháp giải quyết phù hợp.
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>
                  Thông tin được thu thập khi sử dụng dịch vụ của chúng tôi
                </span>
                <span>
                  <br />
                </span>
                <span>
                  Dữ Liệu Cá Nhân có thể được thu thập thông qua hoạt động bình
                  thường hoặc qua việc sử dụng Ứng Dụng, Websites, Dịch Vụ. Một
                  số ví dụ như:
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>Thông tin vị trí của bạn; </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Phản hồi, xếp hạng và khen ngợi;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>Thông tin giao dịch (như phương thức thanh toán);</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thông tin về cách bạn tương tác với Ứng dụng, Website hoặc
                    Dịch vụ của chúng tôi (như các tính năng được sử dụng và nội
                    dung được xem);
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thông tin thiết bị (như thông số phần cứng và số sê-ri, các
                    địa chỉ IP, tên tệp, ứng dụng, vị trí GPS, số IMEI và phiên
                    bản và nhận dạng quảng cáo, hoặc bất kỳ thông tin nào có thể
                    chỉ dẫn về những thay đổi của thiết bị hoặc ứng dụng);
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>Từ các nguồn khác</span>
                <span>
                  <br />
                </span>
                <span>
                  Chúng tôi có thể thu thập Dữ Liệu Cá Nhân, bao gồm nhưng không
                  giới hạn ở tên của bạn, thông tin liên hệ và thông tin nhận
                  dạng khác, từ các nguồn khác khi cần thiết. Những nguồn này
                  bao gồm:
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>Từ các chương trình giới thiệu;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Từ các đối tác kinh doanh của chúng tôi, chẳng hạn như các
                    đối tác xây dựng, sửa chữa, nhà cung cấp dịch vụ thanh toán;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Nhà cung cấp bảo hiểm và tài chính;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Trung tâm tín dụng và các đại lý báo cáo tín dụng khác;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    nguồn dữ liệu công khai có sẵn hoặc nguồn dữ liệu chính phủ;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Khi người dùng của chúng tôi thêm bạn làm người liên lạc
                    khẩn cấp; 
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Khi người dùng của chúng tôi thêm bạn như là người nhận hoặc
                    người thụ hưởng của việc sử dụng bất kỳ Dịch Vụ nào của
                    chúng tôi; 
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Khi bạn sử dụng trò chuyện trong Ứng Dụng; và</span>
                </p>
              </li>
              <li>
                <p>
                  <span>nhà cung cấp hoặc đối tác dịch vụ tiếp thị.</span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>Dữ Liệu Cá Nhân nhạy cảm</span>
                <span>
                  <br />
                </span>
                <span>
                  Một số Dữ Liệu Cá Nhân mà chúng tôi thu thập là nhạy cảm theo
                  đặc tính tự nhiên. Điều này bao gồm Dữ Liệu Cá Nhân liên quan
                  đến chủng tộc của bạn, thông tin số CMND/ số thẻ căn cước công
                  dân, niềm tin tôn giáo, thông tin lý lịch (bao gồm hồ sơ tài
                  chính và lý lịch tư pháp, khi được phép hợp pháp), dữ liệu sức
                  khỏe, khuyết tật, tình trạng hôn nhân và dữ liệu sinh trắc
                  học, nếu phù hợp. Chúng tôi chỉ thu thập thông tin này với sự
                  đồng thuận của bạn và/hoặc tuân thủ nghiêm ngặt các luật áp
                  dụng hiện hành. Trong trường hợp bạn được yêu cầu cung cấp bất
                  kỳ tài liệu hoặc thông tin nào cho chúng tôi cho bất kỳ Mục
                  Đích nào mà có thể chứa Dữ Liệu Cá Nhân nhạy cảm đó (không bắt
                  buộc đối với Mục Đích đó), bạn đồng ý loại bỏ đi Dữ Liệu Cá
                  Nhân nhạy cảm đó trước khi cung cấp tài liệu hoặc thông tin đó
                  cho chúng tôi.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Khi bạn cung cấp Dữ Liệu Cá Nhân của một người khác cho chúng
                  tôi
                </span>
                <span>
                  <br />
                </span>
                <span>
                  Trong một số trường hợp, bạn có thể cung cấp Dữ Liệu Cá Nhân
                  của các cá nhân khác (như vợ/ chồng, thành viên gia đình hoặc
                  bạn bè) cho chúng tôi. Ví dụ, bạn có thể thêm họ như người
                  liên hệ khẩn cấp của bạn, khi bạn sử dụng trò chuyện trong Ứng
                  Dụng hoặc khi bạn thêm họ như là người nhận hoặc người thụ
                  hưởng của bất kỳ việc sử dụng các Dịch Vụ của chúng tôi. Nếu
                  bạn cung cấp cho chúng tôi Dữ Liệu Cá Nhân của họ, bạn cam
                  đoan và bảo đảm rằng bạn đã có được sự chấp thuận của họ để Dữ
                  Liệu Cá Nhân của họ được thu thập, sử dụng và tiết lộ như quy
                  định trong Thông Báo Bảo Mật này.
                </span>
                <span>
                  <span></span>
                </span>
              </p>
            </li>
          </ol>
          <li>
            <p>
              <span>VIỆC CÔNG BỐ, SỬ DỤNG THÔNG TIN THU THẬP</span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>
                  Ứng dụng Sàn giao dịch TMĐT U-Home sử dụng thông tin người
                  dùng cung cấp để
                </span>
                <span>
                  <br />
                </span>
                <span>
                  U-Home có thể sử dụng Dữ Liệu Cá Nhân của bạn cho các mục đích
                  được liệt kê sau đây (“
                </span>
                <span>Mục Đích</span>
                <span>
                  ”). Ngoài ra, nếu bạn sử dụng U-Home ở những mức độ khác nhau,
                  chúng tôi có thể liên kết Dữ Liệu Cá Nhân của bạn đã được thu
                  thập thông qua các mức độ khác nhau đó để tạo điều kiện cho
                  bạn sử dụng Dịch Vụ của chúng tôi và cho các Mục Đích khác
                  được mô tả dưới đây: 
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>Cung cấp dịch vụ và các tính năng</span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Dữ Liệu Cá Nhân của bạn sẽ được sử dụng để cung cấp, cá nhân
                    hóa, duy trì và cải thiện Ứng Dụng, Websites và Dịch Vụ của
                    chúng tôi. Điều này bao gồm sử dụng Dữ Liệu Cá Nhân của bạn
                    để:
                  </span>
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <span>
                      Tương tác để cung cấp cho bạn Dịch Vụ xuyên suốt các lĩnh
                      vực kinh doanh khác nhau của chúng tôi;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Tạo mới, quản trị và cập nhật tài khoản của bạn;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Xử lý, quản lý hoặc xác minh điều kiện thực hiện Dịch Vụ,
                      các ưu đãi, phần thưởng và đăng ký thuê bao với U-Home;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Tiến hành kiểm tra thẩm định và đánh giá/ phân tích rủi
                      ro;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Xác minh danh tính và tuổi của bạn (khi cần thiết);
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Xác nhận dịch vụ của bạn và thực hiện các khoản thanh
                      toán;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Theo dõi lộ trình dịch vụ của bạn và phát hiện các sự thay
                      đổi bất thường;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Cá nhân hóa việc trải nghiệm Ứng Dụng của bạn (chẳng hạn
                      như đề xuất các sản phẩm, dịch vụ có thể liên quan đến
                      bạn, xác định sở thích của bạn hoặc cá nhân hóa trải
                      nghiệm của bạn với U-Home); 
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Làm cho trải nghiệm của bạn trở nên liền mạch hơn, như tự
                      động điền thông tin đăng ký của bạn (như tên hoặc số điện
                      thoại của bạn) từ Dịch Vụ này sang Dịch Vụ khác hoặc khi
                      bạn tham gia vào các cuộc khảo sát của chúng tôi;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Thực hiện các hoạt động nội bộ cần thiết để cung cấp Dịch
                      Vụ, bao gồm khắc phục các lỗi phần mềm và sự cố vận hành,
                      tiến hành phân tích dữ liệu, thử nghiệm và nghiên cứu,
                      giám sát và phân tích xu hướng sử dụng và hoạt động;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Bảo vệ tính bảo mật hoặc tính toàn vẹn của Dịch Vụ và mọi
                      phương tiện hoặc thiết bị được sử dụng để cung cấp Dịch
                      Vụ;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Mời bạn tham gia các khảo sát và nghiên cứu của chúng
                      tôi; 
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Cho phép các đối tác của chúng tôi quản lý và phân bổ
                      nguồn lực; và để thực hiện các dịch vụ cho bạn với tư cách
                      là một Bên Xử lý Dữ liệu, theo như bạn đã đồng thuận với
                      Bên Kiểm soát Dữ liệu (ví dụ tổ chức bạn đã mua hàng hóa
                      hoặc dịch vụ từ đó, và U-Home đang cung cấp những dịch vụ
                      đó thay mặt cho ai) để các dịch vụ đó được triển khai.
                    </span>
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  <span>An toàn và bảo mật</span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Chúng tôi sử dụng dữ liệu của bạn để đảm bảo an toàn và an
                    ninh cho các Dịch Vụ của chúng tôi và cho tất cả người dùng.
                    Điều này bao gồm:
                  </span>
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <span>
                      Sàng lọc người thợ trước khi cho phép họ sử dụng Dịch Vụ
                      của chúng tôi;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Xác định hành vi không an toàn cho người dùng và cung cấp
                      phản hồi cá nhân cho người thợ;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Xác minh danh tính của bạn khi bạn đăng nhập vào U-Home;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Sử dụng thiết bị, vị trí, hồ sơ, cách sử dụng và Dữ Liệu
                      Cá Nhân khác để ngăn chặn, phát hiện và chống lại các hoạt
                      động gian lận hoặc không an toàn;
                    </span>
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  <span>Hỗ trợ khách hàng</span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Chúng tôi sử dụng Dữ Liệu Cá Nhân để giải quyết các vấn đề
                    hỗ trợ khách hàng. Ví dụ, chúng tôi có thể:
                  </span>
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <span>
                      Theo dõi và cải thiện cách phản hồi từ bộ phận hỗ trợ
                      khách hàng của chúng tôi;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Trả lời các câu hỏi, ý kiến ​​và phản hồi; và</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Thông báo cho bạn về các bước thực hiện để giải quyết các
                      vấn đề hỗ trợ khách hàng.
                    </span>
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  <span>Nghiên cứu phát triển và bảo mật</span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Chúng tôi có thể sử dụng Dữ Liệu Cá Nhân mà chúng tôi thu
                    thập để thử nghiệm, nghiên cứu, phân tích và phát triển sản
                    phẩm. Điều này cho phép chúng tôi hiểu và phân tích nhu cầu
                    và sở thích của bạn, bảo vệ dữ liệu cá nhân của bạn, cải
                    thiện và tăng cường an toàn và bảo mật cho các Dịch Vụ của
                    chúng tôi, phát triển các tính năng, sản phẩm và dịch vụ mới
                    và tạo điều kiện phát triển các giải pháp tài chính và bảo
                    hiểm
                  </span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Ngoai ra chúng tôi còn hạn chế, ngăn ngừa các hoạt động phá
                    hủy tài khoản người dùng hoặc các hoạt động giả mạo người
                    dùng
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Mục đích pháp lý</span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Chúng tôi có thể sử dụng Dữ Liệu Cá Nhân mà chúng tôi thu
                    thập cho các mục đích pháp lý, chẳng hạn như để điều tra và
                    giải quyết khiếu nại hoặc tranh chấp, phát hiện/ ngăn chặn/
                    khởi tố tội phạm hoặc khi được cho phép hoặc yêu cầu theo
                    pháp luật áp dụng.Chúng tôi cũng có thể sử dụng Dữ Liệu Cá
                    Nhân của bạn khi chúng tôi được yêu cầu, được tư vấn, đề
                    nghị, đòi hỏi hoặc thỉnh cầu làm như vậy bởi các cố vấn pháp
                    lý của chúng tôi hoặc bất kỳ quy định pháp luật, văn bản
                    dưới luật, thuộc về chính phủ hay các cơ quan có thẩm quyền
                    tại địa phương hoặc nước ngoài.
                  </span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Ví dụ, chúng tôi có thể sử dụng Dữ Liệu Cá Nhân của bạn để:
                  </span>
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <span>
                      Tuân thủ các lệnh của tòa án hoặc các yêu cầu pháp lý,
                      thuộc về chính phủ hoặc quy định khác;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Thực thi Điều Khoản Dịch Vụ của chúng tôi hoặc các thỏa
                      thuận khác; và
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Bảo vệ quyền hoặc tài sản của chúng tôi trong trường hợp
                      khiếu nại hoặc tranh chấp.
                    </span>
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  <span>Tiếp Thị và Quảng Bá</span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Chúng tôi có thể sử dụng Dữ Liệu Cá Nhân của bạn để gửi đến
                    bạn các thông tin tiếp thị liên quan đến các sản phẩm hoặc
                    dịch vụ được cá nhân hóa (điều mà bạn có thể cảm thấy thú vị
                    hoặc có liên quan đến mình dựa trên thông tin tài khoản của
                    bạn) hoặc  của các đối tác nhà tài trợ của U-Home, và các
                    nhà quảng cáo sản phẩm, dịch vụ, sự kiện hoặc chương trình
                    khuyến mại. Ví dụ, chúng tôi có thể:
                  </span>
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <span>
                      Gửi cho bạn những ưu đãi đặc biệt , điều mà bạn có thể cảm
                      thấy thú vị hoặc có liên quan đến mình qua các đề xuất
                      dịch vụ của U-Home; 
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Gửi cho bạn thông báo, bản tin, cập nhật, thư điện tử, tài
                      liệu quảng cáo, đặc quyền, lời chúc mừng lễ hội; và
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Thông báo, mời và quản lý sự tham gia của bạn vào các sự
                      kiện hoặc hoạt động của chúng tôi;
                    </span>
                    <span>
                      <br />
                    </span>
                    <span>
                      Chúng tôi có thể liên lạc tiếp thị như vậy với bạn qua
                      nhiều phương tiện khác nhau, nếu phù hợp, bao gồm tin
                      nhắn, các ứng dụng trò chuyện, thông báo nhanh, cuộc gọi
                      và qua email.
                    </span>
                    <span>
                      <br />
                    </span>
                    <span>
                      Nếu bạn muốn hủy đăng ký về việc nhận các thông tin tiếp
                      thị này, vui lòng nhấp vào liên kết hủy đăng ký trong
                      email hoặc tin nhắn có liên quan. Ngoài ra, bạn cũng có
                      thể cập nhật tùy chọn của mình trong phần cài đặt Ứng
                      Dụng. Xin lưu ý rằng, khi bạn đã hủy đăng ký khỏi các
                      chương trình tiếp thị hoặc khuyến mãi, U-Home vẫn có thể
                      sẽ gửi cho bạn tin nhắn liên quan đến Dịch Vụ (chẳng hạn
                      như thông tin về các giao dịch, phần thưởng… của bạn).{" "}
                    </span>
                    <span>
                      <br />
                    </span>
                    <span>
                      Nếu bạn công khai một số nội dung (chẳng hạn như đánh giá
                      hoặc bình luận) trên nền tảng của U-Home, nó sẽ được xem
                      một cách công khai cùng với Dữ Liệu Cá Nhân của bạn (ví dụ
                      như tên tài khoản, hình ảnh đã tải lên và các thông tin
                      khác mà bạn đã để nó trong nội dung của mình).
                    </span>
                  </p>
                </li>
              </ol>
            </ol>
            <li>
              <p>
                <span>
                  Không sử dụng thông tin cá nhân của người dùng ngoài mục đích
                  xác nhận và liên hệ có liên quan đến giao dịch tại U-Home.
                </span>
                <span>
                  <br />
                </span>
                <span>
                  U-Home sẽ nỗ lực để đảm bảo rằng các nhân viên, người quản lý,
                  tư vấn hoặc chuyên viên kỹ thuật không cung cấp, chia sẻ, phát
                  tán thông tin cá nhân đã thu thập, tiếp cận, kiểm soát cho bên
                  thứ ba, ngoại trừ các trường hợp dưới đây:
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    Trong trường hợp có yêu cầu của pháp luật: Ứng dụng Sàn giao
                    dịch TMĐT U-Home có trách nhiệm hợp tác cung cấp thông tin
                    cá nhân người dùng khi có yêu cầu từ cơ quan tư pháp bao
                    gồm: Viện kiểm sát, Tòa án, Cơ quan công an điều tra liên
                    quan đến hành vi vi phạm pháp luật nào đó của khách hàng.
                    Ngoài ra, không ai có quyền xâm phạm vào thông tin cá nhân
                    của người dùng. 
                  </span>
                </p>
              </li>
            </ol>
          </ol>
          <li>
            <p>
              <span>TIẾT LỘ DỮ LIỆU CÁ NHÂN</span>
              <span>
                <br />
              </span>
              <span>
                Chúng tôi cần chia sẻ Dữ Liệu Cá Nhân với các bên khác nhau cho
                các Mục Đích. Các bên này bao gồm:
              </span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>Người dùng khác</span>
                <span>
                  <br />
                </span>
                <span>Ví dụ:</span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    Nếu bạn là khách hàng, chúng tôi có thể chia sẻ tên tài
                    khoản người dùng, địa điểm sửa chữa của bạn với đối tác thợ
                    của chúng tôi để thực hiện yêu cầu Dịch Vụ của bạn.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Nếu bạn là đối tác thợ, chúng tôi có thể chia sẻ Dữ Liệu Cá
                    Nhân của bạn với khách hàng của bạn bao gồm tên và ảnh của
                    bạn, vị trí và mức đánh giá trung bình;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Nếu bạn sử dụng dịch vụ trò chuyện trong Ứng Dụng, chúng tôi
                    có thể chia sẻ số điện thoại của bạn và tên đăng ký của bạn
                    với các bên khác vào cuộc trò chuyện của bạn.
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>
                  Với các nhà cung cấp dịch vụ và đối tác kinh doanh của{" "}
                </span>
                <span>U-Home</span>
                <span>
                  <br />
                </span>
                <span>
                  Chúng tôi có thể cung cấp Dữ Liệu Cá Nhân cho các nhà cung
                  cấp, chuyên gia tư vấn, đối tác tiếp thị, công ty nghiên cứu
                  và nhà cung cấp dịch vụ hoặc đối tác kinh doanh khác. Điều này
                  bao gồm:
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>Bộ phận xử lý thanh toán và bộ phận hỗ trợ;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>Bộ phận thu hồi nợ;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Trung tâm tín dụng và các đại lý báo cáo tín dụng khác;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Kiểm tra lý lịch và các nhà cung cấp dịch vụ chống rửa tiền;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Nhà cung cấp lưu trữ đám mây;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Đối tác quảng cáo và tiếp thị và nhà cung cấp nền tảng;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Nhà cung cấp phân tích dữ liệu;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>Đối tác bảo hiểm và tài chính;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Các trung gian bên thứ ba có liên quan đến khoản đầu tư được
                    quản lý của các quỹ, như bên môi giới, bên quản lý tài sản
                    và bên giám sát;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Nhà cung cấp dịch vụ thực hiện dịch vụ xác minh danh tính;
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>
                  Với các cố vấn pháp lý của chúng tôi và các cơ quan chính phủ
                </span>
                <span>
                  <br />
                </span>
                <span>
                  Chúng tôi có thể chia sẻ Dữ Liệu Cá Nhân của bạn với các cố
                  vấn pháp lý, các quan chức thực thi pháp luật, cơ quan chính
                  phủ và các bên thứ ba khác. Việc này có thể được thực hiện để
                  hoàn thành mục đích pháp lý (đã đề cập ở trên), hoặc bất kì
                  trường hợp nào sau đây:
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    Khi điều này là cần thiết để phản ứng với một tình huống
                    khẩn cấp đe doạ tính mạng, sức khoẻ hoặc sự an toàn của một
                    thực thể; hoặc
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Khi điều này là cần thiết trong lợi ích công cộng (ví dụ:
                    trong một cuộc khủng hoảng sức khỏe công cộng, cho mục đích
                    truy dấu sự tiếp xúc và bảo vệ cộng đồng của chúng ta).
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>Chuyển giao kinh doanh</span>
                <span>
                  <br />
                </span>
                <span>
                  Chúng tôi có thể chia sẻ Dữ Liệu Cá Nhân của bạn với các bên
                  khác, liên quan đến bất kỳ hoạt động hợp nhất, mua bán, sát
                  nhập, hợp tác, tái cấu trúc, tài chính hoặc bất kỳ giao dịch
                  kinh doanh nào khác. Dữ Liệu Cá Nhân của bạn, vẫn sẽ chịu
                  trách nhiệm bởi chúng tôi theo các Thông Bảo Mật đã tồn tại từ
                  trước mà bạn đã đồng ý.
                </span>
                <span>
                  <br />
                  <br />
                </span>
              </p>
            </li>
          </ol>
          <li>
            <p>
              <span>VIỆC LƯU GIỮ DỮ LIỆU CÁ NHÂN</span>
              <span>
                <br />
              </span>
              <span>
                Chúng tôi lưu giữ Dữ Liệu Cá Nhân của bạn trong khoảng thời gian
                cần thiết để thực hiện các Mục đích được nêu trong Thông Báo Bảo
                Mật này, trừ khi pháp luật yêu cầu hoặc cho phép một khoảng thời
                gian lưu giữ lâu hơn. Khi Dữ Liệu Cá Nhân của bạn không còn cần
                thiết cho Dịch Vụ hoặc Mục Đích, hoặc chúng tôi không còn có mục
                đích kinh doanh hoặc pháp lý để giữ lại Dữ Liệu Cá Nhân của bạn,
                chúng tôi sẽ thực hiện các bước để xoá, tiêu huỷ, ẩn danh hoặc
                ngăn chặn việc truy cập hoặc sử dụng Dữ Liệu Cá Nhân cho bất kỳ
                mục đích nào ngoài việc tuân thủ theo Thông Báo Bảo Mật này,
                hoặc cho mục đích an toàn, bảo mật, phát hiện và phòng chống
                gian lận, phù hợp với các yêu cầu của luật áp dụng hiện hành.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>HỦY BỎ DỮ LIỆU CÁ NHÂN</span>
              <span>
                <br />
              </span>
              <span>
                Người dùng nếu muốn tự thực hiện hủy bỏ dữ liệu cá nhân thì chỉ
                cần đăng nhập vào Ứng dụng U-Home, lựa chọn vào mục xóa tài
                khoản tiếp đó nhập mật khẩu hiện tại và chọn Xóa để xóa tài
                khoản. Như vậy là người dùng đã có thể tự hủy bỏ dữ liệu cá nhân
                của mình. Thông tin hướng dẫn cách xóa tài khoản chi tiết vui
                lòng tham khảo link dưới đây:{" "}
              </span>
              <a href="https://u-home.vn/supports/delete-account">
                https://u-home.vn/supports/delete-account
              </a>
            </p>
          </li>
          <li>
            <p>
              <span>BẢO VỆ DỮ LIỆU CÁ NHÂN</span>
              <span>
                <br />
              </span>
              <span>
                Chúng tôi sẽ thực hiện các biện pháp về mặt tổ chức, kỹ thuật và
                pháp lý phù hợp để bảo vệ Dữ Liệu Cá Nhân của bạn. Các biện pháp
                này có thể bao gồm ngăn chặn tổn thất dữ liệu, hay ngăn chặn
                việc sử dụng hoặc tiếp cận dữ liệu trái phép. Chúng tôi giới hạn
                quyền tiếp cận Dữ Liệu Cá Nhân ở nhân viên trên cơ sở cần biết.
                Chỉ những người được ủy quyền mới được phép xử lý Dữ Liệu Cá
                Nhân của bạn và thông tin của bạn sẽ được bảo mật.
              </span>
              <span>
                <br />
              </span>
              <span>
                Tuy nhiên, lưu ý rằng việc truyền thông tin qua Internet không
                hoàn toàn được bảo mật. Mặc dù chúng tôi sẽ cố gắng hết sức để
                bảo vệ Dữ Liệu Cá Nhân của bạn, chúng tôi không thể đảm bảo an
                toàn dữ liệu của bạn khi truyền qua các phương tiện trực tuyến,
                và do đó chúng tôi không chịu trách nhiệm về rủi ro truyền dữ
                liệu.
              </span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>Quy định thành viên:</span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    Thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi
                    hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu của
                    mình. Thành viên có trách nhiệm thông báo kịp thời cho Ứng
                    dụng sàn giao dịch TMĐT U-home về những hành vi sử dụng trái
                    phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật
                    khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thành viên đăng tin bài rao bán sản phẩm sai chuyên mục Ban
                    quản trị U-home sẽ gửi email thông báo và xóa bài viết đó.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thành viên không sử dụng dịch vụ của Ứng dụng sàn giao dịch
                    TMĐT U-home vào những mục đích bất hợp pháp, không hợp lý,
                    lừa đảo, đe doạ, thăm dò thông tin bất hợp pháp, phá hoại,
                    tạo ra và phát tán virus gây hư hại tới hệ thống, cấu hình,
                    truyền tải thông tin của Ứng dụng sàn giao dịch TMĐT U-home
                    hay sử dụng dịch vụ của mình vào mục đích đầu cơ, lũng đoạn
                    thị trường tạo những đơn đặt hàng, chào hàng giả, kể cả phục
                    vụ cho việc phán đoán nhu cầu thị trường. Trong trường hợp
                    vi phạm thì thành viên phải chịu trách nhiệm về các hành vi
                    của mình trước pháp luật.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thành viên không được thay đổi, chỉnh sửa, gán ghép, copy,
                    truyền bá, phân phối, cung cấp và tạo những công cụ tương tự
                    của dịch vụ do Ứng dụng sàn giao dịch TMĐT U-home cung cấp
                    cho một bên thứ ba nếu không được sự đồng ý của Ứng dụng sàn
                    giao dịch TMĐT U-home trong bản Quy chế này.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thành viên không được hành động gây mất uy tín của Ứng dụng
                    sàn giao dịch TMĐT U-home dưới mọi hình thức như gây mất
                    đoàn kết giữa các thành viên bằng cách sử dụng tên đăng ký
                    thứ hai, thông qua một bên thứ ba hoặc tuyên truyền, phổ
                    biến những thông tin không có lợi cho uy tín của Ứng dụng
                    sàn giao dịch TMĐT U-home.
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>Quy định thông tin đăng tải trên Sàn</span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    Các thông tin đăng ký (thông tin liên hệ và thông tin cá
                    nhân) được yêu cầu phải đầy đủ, đúng chính tả, thống nhất
                    với nhau, không vi phạm các quy định về đăng tin nói chung;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thông tin đăng ký phải trung thực, rõ ràng, không gây nhầm
                    lẫn hoặc hiểu lầm đối với người xem;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Số điện thoại di động và email đăng ký hoặc CMND đăng ký
                    phải hợp lệ, chính xác. Mỗi số điện thoại di động và email
                    chỉ được sử dụng cho một thương nhân;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Đối tác vi phạm các quy định tài khoản bán hàng sẽ áp dụng
                    chế tài xử lý theo quy định của U-home ban hành;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Tài khoản thành viên sẽ bị xóa vĩnh viễn nếu tài khoản vi
                    phạm quá 3 lần.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    U-home có quyền khóa hoặc hủy các tài khoản Người Bán mở ra
                    nhưng không nhằm mục đích bán hàng mà không cần thông báo
                    trước
                  </span>
                  <span>
                    <br />
                  </span>
                  <span>
                    Cơ chế xác thực danh tính và xét duyệt thông tin đăng tải
                    trên gian hàng đối tác:
                  </span>
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <span>Đối với thông tin đăng ký gian hàng:</span>
                  </p>
                </li>
                <ol>
                  <li>
                    <p>
                      <span>
                        Sàn TMĐT U-home khuyến khích thành viên đăng giấy tờ xác
                        thực thông tin cá nhân lên sàn để tăng độ uy tín khi
                        giao dịch trên sàn;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Trong vòng 24h kể từ khi đăng ký, sàn TMĐT U-home sẽ
                        liên hệ lại để xác thực thông tin công khai và đề nghị
                        thành viên cung cấp giấy tờ (bản gốc/sao y) để xác minh
                        danh tính theo quy định;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Các loại giấy tờ khuyến khích chứng thực: CMND/ MST cá
                        nhân
                      </span>
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    <span>
                      Đối với thông tin dịch vụ đối tác cung cấp: Sàn TMĐT
                      U-home yêu cầu người bán phải đăng tải thông tin rõ ràng,
                      mô tả chi tiết dịch vụ, kỹ năng đầy đủ, chính xác tránh
                      gây hiểu lầm cho người mua.
                    </span>
                  </p>
                </li>
              </ol>
            </ol>
            <li>
              <p>
                <span>Thông tin vi phạm trên sàn:</span>
                <span>
                  <br />
                </span>
                <span>
                  Vi phạm một trong các yêu cầu đăng tin sau sẽ bị xóa mà không
                  cần thông báo
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    Nội dung đăng không chất lượng dịch vụ cung cấp, bị khách
                    hàng phản ánh nhiều lần;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Thông tin liên lạc (trong phần đăng ký tài khoản) không đúng
                    (người dùng không thể liên lạc được hoặc bản thân người có
                    trong thông tin liên lạc không đăng tin);
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Tin đăng để số điện thoại/email không đúng quy định;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Đăng ảnh không đúng (ảnh tượng trưng hoặc lấy từ ứng dụng
                    nào khác), ảnh không liên quan đến nội dung, trên ảnh có
                    chứa thông tin liên hệ (điện thoại, email, Ứng dụng), ảnh có
                    chất lượng quá thấp.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Đối với thông tin người dùng chúng tôi sẽ yêu cầu người dùng
                    xác nhận thông tin cá nhân qua email, SMS để đăng ký mở tài
                    khoản.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Quyền hạn của ban quản trị U-home:</span>
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <span>
                      U-home có toàn quyền hủy hoặc khóa tài khoản thành viên vi
                      phạm quy định.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      U-home giữ quyền quyết định về việc lưu giữ hay loại bỏ
                      thông tin đã đăng trên trang ứng dụng này mà không cần báo
                      trước.
                    </span>
                  </p>
                </li>
              </ol>
            </ol>
            <li>
              <p>
                <span>Quy định các chế tài xử lý khi người dùng vi phạm:</span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    U-home kiểm tra đối chiếu thông tin dựa trên thông tin Nhà
                    cung cấp cung cấp, thông tin cung cấp đầy đủ chính xác sẽ
                    được kích hoạt tài khoản, thông tin không chính xác, không
                    đầy đủ, gây hiểu lầm sẽ bị loại bỏ và không được tham gia
                    cung cấp dịch vụ trên ứng dụng sàn TMĐT U-home nữa;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Các thông tin đã được U-home cho hiển thị lên mà có thành
                    viên phản ánh không chính xác, không đầy đủ, gây hiểu nhầm
                    sẽ được U-home xác minh lại, yêu cầu thành viên đăng tin sửa
                    đổi thông tin, nếu không sẽ bị xóa bỏ nội dung;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Các thông tin thành viên bình luận, nhận xét, đánh giá gây
                    hiểu lầm, mang tính chất phá hoại, vi phạm các quy định của
                    quy chế này đều bị xóa, thành viên chịu mọi trách nhiệm với
                    các bình luận của mình;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Trong trường hợp vi phạm thì thành viên phải chịu trách
                    nhiệm về các hành vi của mình trước pháp luật.
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>
                  Những người hoặc tổ chức có thể được tiếp cận với thông tin cá
                  nhân
                </span>
                <span>
                  <br />
                </span>
                <span>
                  Đối tượng được tiếp cận với thông tin cá nhân của Khách
                  hàng/Người dùng thuộc một trong những trường hợp sau:
                </span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>CÔNG TY CỔ PHẦN TẬP ĐOÀN U-HOME;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Các đối tác có ký hợp đồng thực hiện 1 phần dịch vụ do CÔNG
                    TY CỔ PHẦN TẬP ĐOÀN U-HOME cung cấp. Các đối tác này sẽ nhận
                    được những thông tin theo thỏa thuận hợp đồng (có thể 1 phần
                    hoặc toàn bộ thông tin tùy theo điều khoản hợp đồng) để tiến
                    hành hỗ trợ người dùng sử dụng dịch vụ cho Công ty cung cấp;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Cơ quan nhà nước khi có yêu cầu Công ty cung cấp thông tin
                    người dùng để phục vụ quá trình điều tra.
                  </span>
                </p>
              </li>
            </ol>
            <li>
              <p>
                <span>Cam kết bảo mật thông tin cá nhân khách hàng</span>
              </p>
            </li>
            <ol>
              <li>
                <p>
                  <span>
                    Thông tin cá nhân của người dùng trên U-Home được U-Home cam
                    kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá
                    nhân của U-Home. Việc thu thập và sử dụng thông tin của mỗi
                    người dùng chỉ được thực hiện khi có sự đồng ý của khách
                    hàng đó trừ những trường hợp pháp luật có quy định khác;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho
                    bên thứ 3 nào về thông tin cá nhân của người dùng khi không
                    có sự cho phép đồng ý từ người dùng;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn
                    công dẫn đến mất mát dữ liệu cá nhân người dùng, U-Home sẽ
                    có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều
                    tra xử lý kịp thời và thông báo cho người dùng được biết;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Bảo mật tuyệt đối mọi thông tin giao dịch trực tuyến của
                    Người dùng bao gồm thông tin hóa đơn kế toán, chứng từ số
                    hóa tại khu vực dữ liệu trung tâm an toàn cấp 1 của U-Home;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Ban quản lý U-Home yêu cầu các cá nhân khi đăng ký là Người
                    dùng phải cung cấp đầy đủ thông tin cá nhân có liên quan
                    như: Họ tên, Địa chỉ liên lạc, Email, Số chứng minh nhân
                    dân, Điện thoại,... và chịu trách nhiệm về tính pháp lý của
                    những thông tin trên. Ban quản lý U-Home không chịu trách
                    nhiệm cũng như không giải quyết mọi khiếu nại có liên quan
                    đến quyền lợi của Người dùng đó nếu xét thấy tất cả thông
                    tin cá nhân của người dùng đó cung cấp khi đăng ký ban đầu
                    là không chính xác.
                  </span>
                </p>
              </li>
            </ol>
          </ol>
          <li>
            <p>
              <span>
                QUYỀN CỦA NGƯỜI DÙNG ĐỐI VỚI CÁC THÔNG TIN CÁ NHÂN ĐƯỢC THU THẬP
              </span>
              <span>
                <br />
              </span>
              <span>
                Bất kỳ người dùng nào tự nguyện cung cấp Thông tin cá nhân cho
                U-Home đều có các quyền như sau:
              </span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>Yêu cầu xem lại các thông tin được thu thập;</span>
              </p>
            </li>
            <li>
              <p>
                <span>Yêu cầu sao chép lại các thông tin được thu thập;</span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Yêu cầu chỉnh sửa, bổ sung hoặc hủy bỏ thông tin cá nhân đã
                  được thu thập (Trực tiếp bằng cách truy cập vào tài khoản của
                  mình trên Ứng dụng U-Home);
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Người dùng có thể thực hiện các quyền trên bằng cách tự truy
                  cập vào Ứng dụng U-Home hoặc liên hệ với chúng tôi qua
                  Email:&nbsp;
                </span>
                <a href="mailto:uhome.human@gmail.com">
                  <strong>
                    <span>uhome.human@gmail.com</span>
                  </strong>
                </a>
                <span></span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Người dùng có quyền gửi khiếu nại về việc lộ thông tin cá nhân
                  cho bên thứ 3 đến Ban quản trị Ứng dụng sàn giao dịch thương
                  mại điện tử U-Home. Khi tiếp nhận những phản hồi này, U-Home
                  sẽ xác nhận lại thông tin và có trách nhiệm trả lời lý do và
                  hướng dẫn Người dùng khôi phục và bảo mật lại thông tin. Địa
                  chỉ Email liên hệ:{" "}
                </span>
                <a href="mailto:uhome.human@gmail.com">
                  <strong>
                    <span>uhome.human@gmail.com</span>
                  </strong>
                </a>
              </p>
            </li>
          </ol>
          <li>
            <p>
              <span>CÁC BIỆN PHÁP KỸ THUẬT ĐỂ BẢO VỆ DỮ LIỆU CÁ NHÂN</span>
              <span>
                <br />
              </span>
              <span>
                Để đảm bảo an toàn thông tin cá nhân khỏi các nguy cơ do mất,
                trộm cắp, rò rỉ, làm giả hoặc bất cứ thiệt hại nào khác, nhân
                viên kỹ thuật của U-Home sử dụng các biện pháp kỹ thuật, quản lý
                như sau:
              </span>
              <span>
                <br />
              </span>
              <span>
                Hiểu rõ các rủi ro về tấn công mạng, khả năng và mức độ nghiêm
                trọng mà các thiệt hại có thể gây ra đối với quyền và tự do cá
                nhân, U-Home cam kết:
              </span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>
                  Bảo vệ thông tin cá nhân khách hàng, hạn chế rò rỉ hoặc thiệt
                  hại do tấn công mạng hoặc virus máy tính gây ra;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Kiểm soát hệ thống truy cập treo và truy cập trái phép từ bên
                  ngoài;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Triển khai các biện pháp kỹ thuật và tổ chức phù hợp để đảm
                  bảo mức bảo mật phù hợp với rủi ro bao gồm bút danh và mã hóa
                  dữ liệu cá nhân;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Đảm bảo tính bảo mật, toàn vẹn, sẵn sàng và phục hồi hiện tại
                  của hệ thống và dịch vụ xử lý thông tin;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Khôi phục tính sẵn sàng và quyền truy cập vào dữ liệu cá nhân
                  một cách kịp thời trong trường hợp có sự cố vật lý hay kỹ
                  thuật;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Đảm bảo quy trình thường xuyên kiểm tra, đánh giá hiệu quả của
                  các biện pháp kỹ thuật và tổ chức nhằm đảm bảo an ninh cho
                  việc xử lý thông tin.
                </span>
              </p>
            </li>
          </ol>
          <li>
            <p>
              <span>ĐƠN VỊ THU THẬP VÀ QUẢN LÝ THÔNG TIN CÁ NHÂN</span>
              <span>
                <br />
              </span>
              <span>CÔNG TY CỔ PHẦN TẬP ĐOÀN U-HOME</span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>
                  Trụ sở chính: K12/11 Đường Nguyễn Thị Minh Khai, Phường Thạch
                  Thang, Quận Hải Châu, Thành phố Đà Nẵng, Việt Nam
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>Email: </span>
                <span></span>
                <a href="mailto:uhome.human@gmail.com">
                  <strong>
                    <span>uhome.human@gmail.com</span>
                  </strong>
                </a>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Hotline: <a href="tel:19008645">19008645</a>
                </span>
              </p>
            </li>
          </ol>
          <li>
            <p>
              <span>CƠ CHẾ TIẾP NHẬN VÀ GIẢI QUYẾT KHIẾU NẠI LIÊN QUAN</span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>
                  Thành viên có quyền gửi khiếu nại về việc lộ thông tin cá nhân
                  cho bên thứ 3 đến Ban quản trị của Sàn giao dịch thương mại
                  điện từ
                </span>
                <span> Vsmall.vn</span>
                <span> đến địa chỉ Công ty hoặc qua email: </span>
                <a href="mailto:uhome.human@gmail.com">
                  <strong>
                    <span>uhome.human@gmail.com</span>
                  </strong>
                </a>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Công ty có trách nhiệm thực hiện các biện pháp kỹ thuật,
                  nghiệp vụ để xác minh các nội dung được phản ảnh. Thời gian xử
                  lý phản ánh liên quan đến thông tin cá nhân khách hàng là 15
                  ngày.
                </span>
              </p>
            </li>
          </ol>
          <li>
            <p>
              <span>MIỄN TRỪ TRÁCH NHIỆM</span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>
                  U-Home sẽ thực hiện mọi biện pháp và nỗ lực trong việc đảm bảo
                  hệ thống hoạt động tốt nhất hoặc ít nhất là hạn chế các rủi ro
                  nguy hại đến người dùng. Tuy nhiên, chúng tôi không thể đảm
                  bảo rằng thông tin bảo mật của người dùng được tuyệt đối an
                  toàn trước các vụ tấn công mạng, các hành động có hại khác
                  trong thế giới điện tử hiện nay. Bằng việc sử dụng dịch vụ
                  U-Home, người dùng chấp nhận những rủi ro bảo mật vốn có của
                  việc cung cấp thông tin, giao dịch trực tuyến qua internet và
                  sẽ không buộc U-Home phải chịu trách nhiệm cho bất kỳ thiệt
                  hại nào không đến từ U-Home;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Người dùng có trách nhiệm bảo vệ thông tin tài khoản và tự
                  chịu trách nhiệm khi cung cấp thông tin liên quan đến tài
                  khoản, mật khẩu hay các phương thức xác thực truy cập tài
                  khoản thuộc sở hữu của mình cho các bên thứ ba khác. Chúng tôi
                  không chịu trách nhiệm về những vấn đề, bất cập, tranh chấp,
                  thiệt hại phát sinh từ hoặc có liên quan đến việc Người dùng
                  không tuân thủ quy định này;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Trong trường hợp chấp thuận cung cấp thông tin cho chúng tôi,
                  Người dùng có nghĩa vụ cung cấp thông tin đúng sự thực, và cập
                  nhật, điều chỉnh kịp thời các thông tin người dùng của Người
                  dùng khi có sự thay đổi trên thực tế. Chúng tôi không chịu
                  trách nhiệm về những vấn đề, bất cập, tranh chấp, thiệt hại
                  phát sinh từ hoặc có liên quan đến việc Người dùng không tuân
                  thủ quy định này;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Người dùng có trách nhiệm đảm bảo mình có đầy đủ quyền và các
                  chấp thuận cần thiết theo quy định của pháp luật để cung cấp
                  các thông tin người dùng cho chúng tôi, và tự chịu trách nhiệm
                  cũng như bồi thường cho chúng tôi và những bên liên quan đối
                  với bất kỳ khiếu nại, khiếu kiện nào có liên quan tới hoặc
                  phát sinh từ việc Người dùng không đảm bảo quy định tại điều
                  khoản này;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc hoặc khiếu nại nào về
                  Chính sách quyền riêng tư của chúng tôi hoặc các phương pháp
                  thu thập hoặc xử lý dữ liệu của chúng tôi hoặc báo cáo vi phạm
                  bảo mật, vui lòng liên hệ với chúng tôi bằng cách gửi email
                  đến địa chỉ:{" "}
                </span>
                <a href="mailto:uhome.human@gmail.com">
                  <strong>
                    <span>uhome.human@gmail.com</span>
                  </strong>
                </a>
              </p>
            </li>
          </ol>
          <li>
            <p>
              <span>
                 HIỆU LỰC,  CẬP NHẬT VÀ NGÔN NGỮ CỦA CHÍNH SÁCH BẢO MẬT
              </span>
            </p>
          </li>
          <ol>
            <li>
              <p>
                <span>
                  Chính sách bảo mật này có hiệu lực từ ngày phát hành bản sửa
                  đổi cuối, được để cập ở đầu trang này;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Hoạt động kinh doanh của chúng tôi liên tục thay đổi và Chính
                  sách Bảo mật này có thể được cập nhật theo thời gian vì bất kỳ
                  lý do gì. Trừ trường hợp có quy định khác, Chính sách bảo mật
                  này của chúng tôi áp dụng cho tất cả thông tin cá nhân chúng
                  tôi có về Người dùng và tài khoản. Chúng tôi sẽ thông báo cho
                  Người dùng về bất kỳ thay đổi nào đối với Chính sách Bảo mật
                  của chúng tôi bằng cách đăng Chính sách Bảo mật mới tại{" "}
                </span>
                <a href="https://www.u-home.vn/privacy">
                  <span>https://www.u-home.vn/privacy</span>
                </a>
                <span>
                  . Ngày chính sách Bảo mật được sửa đổi lần cuối được xác định
                  ở phần đầu của Chính sách Bảo mật này. Mọi nội dung sửa đổi sẽ
                  có hiệu lực ngay khi được công bố trên website của chúng tôi.
                  Do đó, chúng tôi khuyến nghị Người dùng truy cập định kỳ Dịch
                  vụ của chúng tôi và Chính sách Bảo mật này để kiểm tra bất kỳ
                  thay đổi, cập nhật có thể có. Nếu bạn sử dụng và tiếp tục sử
                  dụng dịch vụ U-Home kể từ ngày có sự thay đổi, việc sử dụng
                  dịch vụ U-Home thể hiện bạn đồng ý bị ràng buộc bởi Điều Khoản
                  này. Các Điều Khoản mới bắt đầu có hiệu lực sau thời điểm
                  chúng tôi thông báo đến bạn và có giá trị áp dụng cả trước,
                  trong (Trong những trường hợp nhất định) và sau khi thông báo
                  đến bạn;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Bên cạnh Chính sách bảo mật này, có thể có các chương trình cụ
                  thể chịu sự điều chỉnh của quy định bảo mật riêng. Chúng tôi
                  khuyến nghị Người dùng đọc các quy định bảo mật riêng này
                  trước khi tham gia bất kỳ các chương trình khác của chúng tôi;
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Theo quy định pháp luật, ngôn ngữ được ưu tiên sử dụng và tham
                  chiếu là tiếng Việt. Trong trường hợp có sự mâu thuẫn trong
                  cách giải thích giữa bản tiếng Việt và các ngôn ngữ khác thì
                  bản tiếng Việt sẽ được ưu tiên tham chiếu.
                </span>
              </p>
            </li>
          </ol>
        </ol>

        <br />
        <br />
      </div>
    </div>
  );
};

export default Privacy;
