const Vie = {
  translation: {
    header: {
      ourCustomers: "Khách hàng của U-Home",
      theBenefits: "Lợi ích",
      downloadTheUhomeApp: "Tải ứng dụng",
    },
    body: {
      slideShow: {
        banner1: "Mảng tường bong tróc",
        banner2: "Bồn rửa, bồn tắm, toilet hỏng nước nóng, rò rỉ lâu ngày",
        banner3: "Điện hư chập chờn",
        banner4: "Tìm hoài không có thợ",
        fixNow: "U-Home đến sửa chữa ngay",
        downloadNow: "Tải App ngay",
      },
      slideComment: {
        title: "Khách hàng nói về U-Home",
      },
      bannerAds: {
        ad1: {
          title: { t1: "Bạn bận rộn?", t2: "Không có nhiều thời gian?" },
          line: {
            l1: "U-Home giúp bạn có thể tìm kiếm thợ nhanh chóng tự động",
            l2: "Quản lí tiến độ công việc online",
            l3: "Giao tiếp báo cáo thường xuyên giữa bạn và thợ.",
          },
        },
        ad2: {
          title: { t1: "Bạn lo lắng về chất lượng công việc?" },
          line: {
            l1: "Chất lượng thợ được kiểm tra đánh giá theo tiêu chuẩn của đội nhóm giàu kinh nghiệm",
            l2: "Đánh giá nhận xét song phương, thợ - hộ gia đình, mọi đánh giá của bạn sẽ là thước đo cho người thợ làm việc",
          },
        },
        ad3: {
          title: { t1: "An tâm về an ninh" },
          line: {
            l1: "Thông tin người thợ xác minh",
            l2: "Mọi vấn đề được chúng tôi tracking và kiểm tra an toàn.",
          },
        },
        ad4: {
          title: { t1: "Sửa chữa không lo về giá" },
          line: {
            l1: "Chỉ trao đổi thời gian sửa chữa, bạn và thợ không cần mặc cả",
            l2: "Chi phí luôn hợp lí với thực tế",
            l3: "Chi phí phụ (nguyên vật liệu, vật tư) độc lập ",
          },
        },
      },
      bannerSupply: {
        title: "U-Home có những gì?",
        block1: {
          title: "Vấn đề của bạn sẽ được hỗ trợ ngay lập tức",
          line: {
            l1: "- Tạo nội dung yêu cầu nhanh chóng",
            l2: "- Thợ sẽ liên hệ với bạn ngay lập tức",
          },
        },
        block2: {
          title: "Đánh giá chất lượng và phản hồi công việc",
          line: {
            l1: "- Quá trình làm việc được đánh giá và phản hồi trực tiếp trên ứng dụng.",
            l2: "- Phản hồi của bạn là tiêu chí quan trọng của người thợ",
          },
        },
        block3: {
          title: "Không lo về giá",
          line: {
            l1: "- Bạn và thợ chỉ trao đổi về thời gian làm việc",
            l2: "- Chi phí sẽ được hệ thống tính toán hợp lí nhất",
            l3: '- Luôn có những "free code" siêu khủng',
          },
        },
        block4: {
          title: "Helper luôn bên bạn",
          line: {
            l1: "- Đội ngũ helper luôn bên bạn 24/7",
            l2: "- Có U-Home - vấn đề của bạn được giải quyết",
          },
        },
      },
      bannerApp: {
        title: "Cài đặt ứng dụng ngay",
        description:
          "U-Home - Mang đến bạn trải nghiệm hoàn toàn mới. Vấn đề của bạn, luôn được giải quyết với U-Home.",
      },
      feedback: {
        customer: "Khách Hàng",
        electricianPlumber: "Thợ Điện Nước",
        plumber: "Thợ Sửa Nước",
        electrician: "Thợ Điện Máy",
        designer: "Thiết Kế",
        developer: "Lập Trình Viên",
        freelance: "Nghề Tự Do",
        officeStaff: "Nhân viên văn phòng",
        supermarketManager: "Quản lý siêu thị",
        refrigeration: "Thợ điện lạnh",
        mechanic: "Thợ cơ khí",
      },
    },
    footer: {
      paymentIntermediary1: "Công ty cổ phần tập đoàn U-HOME",
      paymentIntermediary2: "Mã số doanh nghiệp: 0402091984",
      information: "Thông tin",
      support: "Hỗ trợ",
      privacyPolicy: "Chính sách",
      termsAndConditions: "Điều kiện và điều khoản",
    },
    download: {
      title: "Tải ứng dụng <br /> U-Home ngay!",
    },
  },
};

export default Vie;
